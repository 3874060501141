<template>
    <v-row no-gutters justify="center" align-content="center" class="pa-5" style="height: 100%;">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <!-- NOTE: we use this app-splash-loader instead of v-progress-circular because we want this to look exactly like the index.html splash loader so the visual transition is smooth for the user as the rest of the UI loads -->
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="interactionError">
                <v-alert type="error">
                    We cannot process this request.
                </v-alert>
            </template>
            <template v-if="errorUnauthorizedInteraction">
                <!-- <v-alert type="error">
                    Unauthorized
                </v-alert>
                <p class="text-h5 font-weight-light mt-6">Did you start in another browser? Try opening the link in that browser, or start over in this one.</p>
                <p class="text-body-1 font-weight-light text-center"><router-link :to="{ name: 'front' }">Start over</router-link></p> -->
                <v-card>
                    <v-app-bar color="white red--text" elevation="0">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" color="red"></font-awesome-icon>
                        <span class="ml-2">We cannot process this request</span>
                    </v-app-bar>
                    <v-card-text>
                        <p class="text-body-1 font-weight-light">Did you start in another browser? Try opening the link in that browser.</p>
                        <p class="text-body-1 font-weight-light">It's possible the request may have expired -- you can try to start over.</p>
                        <p class="text-body-1 font-weight-light"><router-link :to="{ name: 'front' }">Start over</router-link></p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="serverError">
                <v-alert type="error">
                    We cannot process this request due to a temporary server error. Reload the page to try again. If it&apos;s not resolved in a few minutes, please contact customer support.
                </v-alert>
            </template>
        </v-col>
    </v-row>
</template>
<style lang="css">
.app-splash-loader {
    border: 4px solid #BBDEFB; /* blue lighten-4 */
    border-top: 4px solid #2196F3; /* blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { client } from '@/client';

// constants
const IA_LOGIN = 'login';
const IA_SIGNUP = 'signup';
const IA_VERIFY_EMAIL = 'verify_email';
const INTENT_LOGIN = 'login';
const INTENT_SIGNUP = 'signup';
const ROUTE_LOGIN = 'login';
const ROUTE_SIGNUP = 'signup';
const ROUTE_FRONT = 'front';

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,
        intent: null, // login or signup
        email: false, // login and signup interactions may include an email address that we need to forward to next step
        product: null, // signup interaction may include a product lookup key that we need to forward to next step
        interactionId: null,
        interactionError: false,
        errorUnauthorizedInteraction: false,
        serverError: false,
        clientError: false,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isError() {
            return this.serverError || this.interactionError || this.errorUnauthorizedInteraction;
        },
    },
    methods: {
        async init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.$route.query.cryptium_id_token) {
                await this.verifyEmail(this.$route.query.cryptium_id_token);
            }
            if (this.$route.query.etherlink_token) {
                // TODO: it's not email verification anymore with etherlink, we need a new function and API for recording the user's etherlink id so we can send authenticated messages later
                // await this.verifyEmail(this.$route.query.etherlink_profile_token);
            }
            if (this.$route.query.token) {
                await this.resumeInteraction(this.$route.query.token);
            }
            if (this.interactionId === null && this.$route.query.i) {
                this.interactionId = this.$route.query.i;
            }
            if (this.interactionId) {
                await this.loadInteraction(this.interactionId);
            }
            if (this.intent === null && this.$route.query.intent) {
                this.intent = this.$route.query.intent;
            }
            if (this.intent) {
                this.resumeIntent(this.intent);
            }
            this.isViewReady = true;
        },
        /**
         * Called when user returns from EtherLink with `etherlink_profile_token`
         */
        async verifyEmail(cryptiumIdToken) {
            try {
                this.$store.commit('loading', { verifyEmail: true });
                const request = {
                    cryptium_id_token: cryptiumIdToken,
                };
                console.log(`verifyEmail request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.checkVerifyEmail(request);
                console.log(`verifyEmail response ${JSON.stringify(response)}`);
                switch (response?.status) {
                case 'verified_email':
                    if (response.email) {
                        this.email = response.email;
                    }
                    break;
                case 'error':
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    break;
                default:
                    console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to verify email', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        // TODO: change to unknownError?
                        this.interactionError = true;
                        // this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    // TODO: change to unknownError?
                    this.interactionError = true;
                    // this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { verifyEmail: false });
            }
        },
        async resumeInteraction(token) {
            try {
                this.$store.commit('loading', { resumeInteraction: true });
                const response = await this.$client.main().interaction.resume(token);
                console.log(`resumeInteraction response: ${JSON.stringify(response)}`);
                const { interaction_id: interactionId, next_route: nextRoute, error } = response;
                if (error) {
                    this.interactionError = true;
                } else if (nextRoute) {
                    console.log(`resumeInteraction nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else {
                    this.interactionId = interactionId;

                    // since the destination route is the same, we expect all the state to remain,
                    // but put it in the URL just in case it is cleared by the navigation
                    const query = {
                        i: interactionId,
                        email: this.email,
                        product: this.product,
                    };

                    this.$router.replace({ name: 'interaction', query });
                }
            } catch (err) {
                console.error('failed to activate token', err);
                this.serverError = true;
            } finally {
                this.$store.commit('loading', { resumeInteraction: false });
            }
        },
        async loadInteraction(interactionId) {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(interactionId);
                console.log(`loadInteraction response: ${JSON.stringify(response)}`);
                const { type, /* next, */ state = {} } = response;
                console.log(`interaction.vue: loadInteraction: interaction id: ${interactionId} type: ${type} state: ${JSON.stringify(state)}`);
                // switch (type) {
                // case 'create_enterprise':
                //     this.createEnterprise({ type, next, state }, prevInteraction);
                //     break;
                // default:
                //     console.error('interaction.vue: unknown interaction type: %s', type);
                //     this.interactionError = true;
                //     break;
                // }
                // TODO: so if it's login, redirect to login; if it's sign up , redirect to sign up  ...  or jsut set this.intent and this.intentData (to use as query parameters) and let resumeIntent do that redirect.
                switch (type) {
                case IA_LOGIN:
                    this.intent = INTENT_LOGIN;
                    break;
                case IA_SIGNUP: {
                    this.intent = INTENT_SIGNUP;
                    const { email, product } = state;
                    if (email) {
                        this.email = email;
                    }
                    if (product) {
                        this.product = product;
                    }
                    // this.display_name = state.display_name;
                    break;
                }
                case IA_VERIFY_EMAIL: {
                    const { email, intent, product } = state;
                    this.intent = intent;
                    this.email = email;
                    this.product = product;
                    break;
                }
                default:
                    console.error(`unexpected interaction type: ${JSON.stringify(type)}`);
                    break;
                }
            } catch (err) {
                console.error('failed to activate token', err);
                if (err.response?.status === 401 || err.response?.status === 403) {
                    this.errorUnauthorizedInteraction = true;
                } else if (err.response?.status >= 400 && err.response?.status < 500) {
                    this.clientError = true;
                } else if (err.response?.status >= 500) {
                    this.serverError = true;
                } else {
                    this.unknownError = true;
                }
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        resumeIntent(intent) {
            // query parameter values may be null depending on how user arrived
            const query = {};
            if (this.email) {
                query.email = this.email;
            }
            if (this.product) {
                query.product = this.product;
            }
            if (this.interactionId) {
                query.i = this.interactionId;
            } else if (this.$route.query.i) {
                query.i = this.$route.query.i;
            }
            switch (intent) {
            case INTENT_LOGIN:
                this.$router.replace({ name: ROUTE_LOGIN, query });
                break;
            case INTENT_SIGNUP:
                query.step = 'display_name';
                this.$router.replace({ name: ROUTE_SIGNUP, query });
                break;
            default:
                this.$router.replace({ name: ROUTE_FRONT, query });
                break;
            }
        },
        // createEnterprise({ state }) {
        //     const { isVerified, isExisting } = state;
        //     if (isExisting) {
        //         this.$router.replace({ path: '/create-enterprise/conflict', query: { i: this.interactionId } });
        //         return;
        //     }
        //     if (isVerified) {
        //         this.$router.replace({ path: '/create-enterprise/login', query: { i: this.interactionId } });
        //         return;
        //     }
        //     this.interactionError = true;
        // },
    },
    // created() {
    //     if (this.$route.query.error) {
    //         console.log(`interaction.vue: created, error: ${this.$route.query.error}`);
    //         this.loading = false;
    //         switch (this.$route.query.error) {
    //         case 'unauthorized':
    //             this.errorUnauthorizedInteraction = true;
    //             break;
    //         default:
    //             this.interactionError = true;
    //         }
    //     } else {
    //         this.interactionId = this.$route.query.i;
    //         console.log(`interaction.vue: created, interactionId: ${this.interactionId}`);
    //     }
    // },
    mounted() {
        this.init();
    },
};
</script>
